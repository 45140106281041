.settings{
    display:flex;

}
.settingsWrapper{
    flex:9;
    padding:20px;
}
.settingsTitle{
    display:flex;
    align-items:center;
    justify-content: space-between;
}
.settingsUpdateTitle{
    font-size: 30px;
    margin-bottom: 20px;
    color: lightcoral;
}
.settingsDeleteTitle{
    color:red;
    font-size: 12px;
    cursor: pointer;
}
.settingsForm{
    display:flex;
    flex-direction: column ;
}
.settingspp{
    display: flex;
    align-items: center;
    margin: 10px 0;
}
.settingspp > img {
    width: 70px;
    height: 70px;
    border-radius: 20px;
    object-fit: cover;
}
.settingsPpIcon{
    width:25px;
    height: 25px;
    border-radius: 50%;
    background-color: coral;
    color:white;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
}
.settingsForm > label{
    font-size: 20px;
    margin-top: 20px;
}
.settingsForm > input{
    color:gray;
    margin:10px 0;
    height: 30px;
    border: none;
    border-bottom: 1px solid lightgray;
}
.settingsSubmit{
    width:150px;
    align-self: center;
    border: none;
    border-radius: 10px;
    background-color: teal;
    color:white;
    padding: 10px;
    margin-top: 20px;
    cursor: pointer;

}
@media (max-width: 768px) {
    .settingsWrapper {
        flex: 100%; /* Changed flex to take up full width on smaller screens */
    }

    .settingsUpdateTitle {
        font-size: 20px; /* Further decreased font size for smaller screens */
    }

    .settingsForm > label {
        font-size: 16px; /* Further decreased font size for smaller screens */
    }
}