.register{
    height:calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(rgba(255,255,255,0.6),rgba(255,255,255,0.5)),url("https://images.pexels.com/photos/316466/pexels-photo-316466.jpeg?auto=compress&cs=tinysrgb&w=600");
background-size: cover;
align-items: center;
}
.registerTitle{
    font-size: 50px;
}
.registerForm{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}
.registerForm > label{
    margin:10px 0;
}
.registerInput{
    padding:10px;
    background-color: white;
    border: none;
}
.registerButton{
    margin-top: 20px;
    cursor: pointer;
    background-color: teal;
    border: none;
    color: white;
    border-radius: 10px;
    padding:10px;
}
.registerRegisterButton{
    position: absolute;
    top:60px;
    right: 20px;
    background-color: lightcoral;
    cursor: pointer;
    border: none;
    padding: 10px;
    color: white;
    border-radius: 10px;
}
@media (max-width: 768px) {
    .registerTitle {
        font-size: 30px; 
    }

    .registerInput, .registerButton {
        width: 90%; 
    }

    .registerRegisterButton {
        top: 80px; 
        right: 20px; 
    }
}