.header {
    margin-top: 10px;
}

.headerTitles {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Lora', serif;
    color: #444;
    position: relative; /* Added for better control of absolute positioned elements */
}

.headerTitleSm {
    position: absolute;
    top: 18%;
    font-size: 40px;
}

.headerTitleLg {
    position: absolute;
    top: 20%;
    font-size: 100px;
}

.headerImg {
    width: 100%;
    height: 450px;
    margin-top: 80px;
    object-fit: cover;
}

/* Media Queries for responsiveness */
@media (max-width: 1200px) {
    .headerTitleSm {
        font-size: 30px;
        top: 16%;
    }

    .headerTitleLg {
        font-size: 80px;
        top: 18%;
    }
}

@media (max-width: 992px) {
    .headerTitleSm {
        font-size: 25px;
        top: 14%;
    }

    .headerTitleLg {
        font-size: 60px;
        top: 16%;
    }
}

@media (max-width: 768px) {
    .headerTitleSm {
        font-size: 20px;
        top: 12%;
    }

    .headerTitleLg {
        font-size: 50px;
        top: 14%;
    }

    .headerImg {
        height: 350px;
    }
}

@media (max-width: 576px) {
    .headerTitleSm {
        font-size: 18px;
        top: 10%;
    }

    .headerTitleLg {
        font-size: 40px;
        top: 12%;
    }

    .headerImg {
        height: 300px;
    }
}
