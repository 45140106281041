.top {
    width: 100%;
    height: 50px;
    background-color: white;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 99;
}

.topLeft, .topRight {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topIcon {
    font-size: 20px;
    margin-right: 10px;
    color: #444;
    cursor: pointer;
}

.topImage {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.topCentre {
    flex: 6;
}

.topList {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
}

.topListItem {
    margin-right: 10px; 
    font-size: 16px; 
    font-weight: 300;
    cursor: pointer;
}

.topSearchIcon {
    font-size: 18px;
    color: #666;
    cursor: pointer;
    margin-left: 10px;
}


@media (max-width: 768px) {
    .topListItem {
        margin-right: 5px; 
        font-size: 14px; 
    }

    .topSearchIcon {
       display: none;
    }
}
