.login{
    height:calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(rgba(255,255,255,0.6),rgba(255,255,255,0.5)),url("https://media.istockphoto.com/id/472336288/photo/out-of-focus-office-open-corridor-background.jpg?b=1&s=612x612&w=0&k=20&c=zEMY9qEOoJXaPIpys1ZxNajdJwiAgB0YQiAub8GcdLI=");
background-size: cover;
align-items: center;
}
.loginTitle{
    font-size: 50px;
}
.loginForm{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}
.loginForm > label{
    margin:10px 0;
}
.loginInput{
    padding:10px;
    background-color: white;
    border: none;
}
.loginButton{
    margin-top: 20px;
    cursor: pointer;
    background-color: lightcoral;
    border: none;
    color: white;
    border-radius: 10px;
    padding:10px;
}
.loginRegisterButton{
    position: absolute;
    top:60px;
    right: 20px;
    background-color: teal;
    cursor: pointer;
    border: none;
    padding: 10px;
    color: white;
    border-radius: 10px;
}
@media (max-width: 768px) {
    .loginTitle {
        font-size: 30px; 
    }

    .loginInput, .loginButton {
        width: 90%; 
    }

    .loginRegisterButton {
        top: 80px; 
        right: 20px; 
    }
}